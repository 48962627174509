<template>
  <div class="s-page">
    <van-nav-bar
      fixed
      placeholder
      :title="$t('assets.message')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="s-content">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
        @load="fetchData"
      >
        <van-cell v-for="(item, index) in list" :key="item.id" @click="handleDetails(item)">
          <template #title>
            <van-badge dot v-if="!item.readtime"></van-badge>
            <span class="custom-title">{{ item.title }}</span>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'message',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      maxPage:1,
      limit: 2
    };
  },
  mounted() {
    // this.fetchData()
  },
  methods: {
    // 获取消息列表
    async fetchData() {
      const { data } = await this.$http.get('/home/user/msglist', {
        params: {
          page: this.page,
          limit: this.limit
        }
      })
      if (data) {
        if (data.code === 200) {
          this.list = this.list.concat(data.data.list);
          this.maxPage = Math.ceil(data.data.count/this.limit) || 1;
          this.loading = false;

          if (this.page >= this.maxPage) {
            this.finished = true;
          }

          this.page++;
        }
      }
    },
    // 查看详情
    handleDetails(item) {
      // 路由跳转
      this.$router.push({
        path: '/assets/messageDetails',
        query: {
          id: item.id
        }
      })
      if (!item.readtime) this.readMessage(item)
    },
    // 阅读未读消息
    async readMessage(item) {
      const { data } = await this.$http.get('/home/user/msgread', {
        params: {
          id: item.id
        }
      })
      if (data) {
        if (data.code === 200) {
          item.readtime = new Date().getTime();
        }
      }
    }
  },
}
</script>

<style scoped lang="less">
.s-content{
  text-align: left;
  background: #f8f8f8;
  .van-cell{
    background: #fff;
    .custom-title{
      padding-left: 0.4375rem;
    }
  }
}
</style>
